import React, {Component} from "react";
import pigError from "../../resources/images/logos/fetura_cloud_colour.svg"
import {withTranslation} from "react-i18next";
import "./maintenanceView.scss"
import {connect} from "react-redux";
import i18next from "i18next";
import * as moment from "moment";
import {withRouter} from "react-router-dom";


export class MaintenanceView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentMaintenance: undefined
        }
        if (!props.inMaintenance) {
            props.history.push("/");
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.currentMaintenance !== props.currentMaintenance) {
            return {
                currentMaintenance: props.currentMaintenance,
                time: moment.duration((props.currentMaintenance.DtaEndTime - Date.now()), "milliseconds")
            }
        }
    };

    componentDidMount() {
        const {time} = this.state;
        this.time = time;
        this.setState({
            timer: setInterval(() => {
                this.time = moment.duration(this.time - 1000, 'milliseconds');
                this.setState({
                    time: this.time
                })
            }, 1000)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {history, inMaintenance} = this.props;
        const {currentMaintenance, time, timer} = this.state;
        if (prevProps.currentMaintenance !== currentMaintenance) {
            this.setState({
                currentMaintenance: this.props.currentMaintenance
            });
        }
        if (prevState.time !== time) {
            if (time <= 0) {
                clearInterval(timer);
                history.push("/");
            }
        }
        if (prevProps.inMaintenance !== inMaintenance) {
            if (!inMaintenance) {
                if (!!timer) {
                    clearInterval(timer);
                }
                history.push("/");
            }
        }
    }

    componentWillMount() {
        clearInterval(this.state.timer)
    }



    render() {

        const {currentMaintenance, time} = this.state;
        let tmp = {
            title: i18next.t("maintenance.serviceNotAvalible"),
            text: currentMaintenance ? i18next.t("maintenance.appUpdate.date", {
                days: time.days(),
                hours: time.hours(),
                minutes: time.minutes(),
                seconds: time.seconds()
            }) : i18next.t("maintenance.appUpdate.nodate"),
            type: 1
        };

            return (
                <div className={"maintenance"}>

                    <h1> {tmp.title} </h1>
                    <div className="content">
                        <img src={pigError} alt={"maintenance"}/>
                        <p>{i18next.t("maintenance.appUpdate.text")} </p>
                        <p>{tmp.text}</p>
                    </div>

                </div>
            );
    }
}

MaintenanceView = connect(store => ({
    currentMaintenance: store.maintenance.currentMaintenance,
    inMaintenance: store.maintenance.inMaintenance
}))(MaintenanceView);

MaintenanceView = withRouter(MaintenanceView);

export default withTranslation()(MaintenanceView);